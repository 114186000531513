import dayjs from "dayjs";
import { ToWords } from "to-words";
import { useEffect, useState } from "react";
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const generateNameInitial = (firstName, lastName) => {
  let nameInitial = "";
  if (firstName) {
    nameInitial = nameInitial + firstName[0].substring(0, 1).toUpperCase();
  }
  if (lastName) {
    nameInitial = nameInitial + lastName[0].substring(0, 1).toUpperCase();
  }
  return nameInitial;
};

export const districtFormater = (district) => {
  if (district) {
    return (
      district
        ?.trim()
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase())
        .replace(/[^a-zA-Z0-9\s-]/g, "") || ""
    );
  } else {
    return "";
  }
};

export const FormatFullName = (firstName, middleName, lastName) => {
  const nameParts = [firstName, middleName, lastName].filter(Boolean);
  return nameParts.join(" ");
};

export const returnState = (text) => {
  try {
    if (String(text).includes("&")) {
      const spl = String(text).split("&");
      return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
        .charAt(1)
        .toUpperCase()}`;
    } else {
      const isSpace = /\s/g.test(text);
      if (isSpace) {
        const spl = String(text).split(" ");
        return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
          .charAt(0)
          .toUpperCase()}`;
      } else {
        return text;
      }
    }
  } catch (error) {
    return text;
  }
};

export const FormatAddressWithShortState = (address, dist, state, pincode) => {
  let shortState = returnState(state);
  const addressParts = [address, dist, shortState, pincode].filter(Boolean);
  return addressParts.join(", ");
};

export const DOB_in_Word = (DOB) => {
  const toWords = new ToWords();
  const WordDOB = dayjs(DOB).isValid()
    ? `${toWords.convert(dayjs(DOB).format("D"))} ${dayjs(DOB).format(
        "MMMM"
      )} ${toWords.convert(dayjs(DOB).format("YYYY"))}`
    : "--";

  return WordDOB;
};

export const checkImageURLisValid = async (url) => {
  if (url) {
    const res = await fetch(url);
    const buff = await res.blob();
    const data = buff.type.startsWith("image/");
    if (data) {
      return true;
    } else {
      return false;
    }
    // const isValid = url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    // return isValid;
  } else {
    return false;
  }
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};
const ITEM_HEIGHT2 = 48;
const ITEM_PADDING_TOP2 = 8;
export const MenuProps2 = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT2 * 4.5 + ITEM_PADDING_TOP2,
      width: 250,
    },
  },
};

export const NumberInWord = (price) => {
  var sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
    dblDigit = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ],
    tensPlace = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ],
    handle_tens = function (dgt, prevDgt) {
      return 0 == dgt
        ? ""
        : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (
        (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
        (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      );
    };

  var str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if (((price += ""), isNaN(parseInt(price)))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
      switch (
        ((digit = price[digitIdx] - 0),
        (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
        price.length - digitIdx - 1)
      ) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(
            0 != digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                    ? " and"
                    : "")
              : ""
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(
            0 != digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                    ? " and"
                    : " Crore")
              : ""
          );
      }
    str = words.reverse().join("");
  } else str = "";
  return str;
};
export const IndianCurrency = (text) => {
  return Number(text ? text : 0).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
};
export const formatInputValueInotCurrency = (val) => {
  // Strip out non-digit characters
  const cleanValue = val.replace(/[^\d]/g, "");

  // Format as currency with commas for thousands
  return new Intl.NumberFormat("en-IN").format(cleanValue);
};
export const getDuration = (endDate, startDate) => {
  let start = new Date(startDate);
  let end = dayjs(endDate);

  return `${end.diff(start, "months")}M`;
};
export const getMonthsBetweenDates = (startDate, endDate) => {
  const months = [];
  let currentMonth = dayjs(startDate);

  while (
    currentMonth.isBefore(endDate) ||
    currentMonth.isSame(endDate, "month")
  ) {
    months.push(currentMonth.format("MMMM"));
    currentMonth = currentMonth.add(1, "month");
  }

  return months;
};

export const getPaymentDetailsString = (paymentDetails) => {
  return Object.values(paymentDetails || {})
    .map((details) => {
      let detailsString = "";
      // if ("amount" in details) detailsString += `amount: ${details.amount}, `;
      if ("UPI_platform" in details)
        detailsString += `UPI platform: ${details.UPI_platform}, `;
      if ("upiNumber" in details)
        detailsString += `upiNumber: ${details.upiNumber}, `;
      if ("transactionId" in details)
        detailsString += `transactionId: ${details.transactionId}, `;
      // if ("timeStamp" in details) {
      //   const istDate = moment(details.timeStamp).tz("Asia/Kolkata").format();
      //   detailsString += `timeStamp: ${istDate}, `;
      // }
      if ("referenceNumber" in details)
        detailsString += `referenceNumber: ${details.referenceNumber}, `;
      if ("transactionNumber" in details)
        detailsString += `transactionNumber: ${details.transactionNumber}, `;
      if ("chequeNumber" in details)
        detailsString += `chequeNumber: ${details.chequeNumber}, `;
      if ("depositionDate" in details)
        detailsString += `depositionDate: ${details.depositionDate}, `;
      if ("approvalCode" in details)
        detailsString += `approvalCode: ${details.approvalCode}, `;
      if ("cardHolderName" in details)
        detailsString += `cardHolderName: ${details.cardHolderName}, `;
      if ("cardNumber" in details)
        detailsString += `cardNumber: ${details.cardNumber}, `;
      return detailsString.slice(0, -2); // Remove the last comma and space
    })
    .join(", ");
};

export const calculateTotalsAndAppendSummaryRowPdf = (
  rows,
  selectedOptionsForPdf,
  totalColumns
) => {
  const totalIndices = totalColumns.map((column) =>
    selectedOptionsForPdf.indexOf(column)
  );

  // Calculate totals
  const totals = rows.reduce((acc, row) => {
    totalIndices.forEach((index, i) => {
      const value = parseFloat(row[index]);
      acc[i] += isNaN(value) ? 0 : value;
    });
    return acc;
  }, new Array(totalIndices.length).fill(0));

  // Create the summary row
  const summaryRow = new Array(selectedOptionsForPdf.length).fill("");
  totalIndices.forEach((index, i) => {
    summaryRow[index] = totals[i];
  });

  return summaryRow;
};

// export const BlinkingNumber = () => {
//   const [visible, setVisible] = useState(true);
//   const [intervalId, setIntervalId] = useState(null);

//   const startBlinking = () => {
//     const id = setInterval(() => {
//       setVisible((v) => !v);
//     }, 1000); // Change this to adjust the blinking speed
//     setIntervalId(id);
//   };

//   const stopBlinking = () => {
//     clearInterval(intervalId);
//   };

//   useEffect(() => {
//     startBlinking();
//     return () => stopBlinking();
//   }, []);

//   return (
//     <div onMouseEnter={stopBlinking} onMouseLeave={startBlinking}>
//       <a
//         href={`https://wa.me/919792669957`}
//         target="blank"
//         style={{
//           textDecoration: "none",
//           color: visible ? "green" : "transparent",
//         }}
//       >
//         9792 66 9957
//       </a>
//       {"  "}
//       <a
//         href={`tel: ${9569866591}`}
//         style={{
//           textDecoration: "none",
//           color: visible ? "blue" : "transparent",
//         }}
//       >
//         9569 86 6591
//       </a>
//     </div>
//   );
// };

export const BlinkingNumber = () => {
  return (
    <div>
      <a
        href="https://wa.me/919792669957"
        target="blank"
        style={{
          textDecoration: "none",
          color: "green",
        }}
      >
        9792 66 9957
      </a>
      {"  "}
      <a
        href="tel:9569866591"
        style={{
          textDecoration: "none",
          color: "blue",
        }}
      >
        9569 86 6591
      </a>
    </div>
  );
};
