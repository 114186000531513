import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { IndianCurrency } from "app/utils/helper";
import LabelTextComponent from "./LabelTextComponent";
import TextFieldLabel from "./TextFieldLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
const initialObj = {
  CashModeEarning: 0,
  ChequeModeEarning: 0,
  NEFTModeEarning: 0,
  CardModeEarning: 0,
  UpiModeEarning: 0,

  CashModeDeduction: 0,
  ChequeModeDeduction: 0,
  NEFTModeDeduction: 0,
  CardModeDeduction: 0,
  UpiModeDeduction: 0,
};
const PaymentModeComponent2 = ({
  data = {},
  onClickSubmit = () => {},
  loading = false,
}) => {
  const dt = new Date();
  const [paymentMode, setPaymentMode] = useState(initialObj);
  const [state, setState] = useState({
    cashMode: {
      amount: "",
      reference_no: "",
    },
    chequeMode: {
      amount: "",
      reference_no: "",
    },
    neftMode: {
      amount: "",
      reference_no: "",
    },
    cardMode: {
      amount: "",
      reference_no: "",
    },
    upiMode: {
      amount: "",
      reference_no: "",
    },
    transactionDate: dt,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [mode, field] = name.split(".");
    setState((prev) => ({
      ...prev,
      [mode]: {
        ...prev[mode],
        [field]: value,
      },
    }));
  };

  const cashModeTr = data?.trnasactionArray
    ?.filter((item) => item?.paymentMode === "CashMode")
    ?.reduce((sum, value) => sum + Number(value?.transactionAmount ?? 0), 0);
  const cardModeTr = data?.trnasactionArray
    ?.filter((item) => item?.paymentMode === "CardMode")
    ?.reduce((sum, value) => sum + Number(value?.transactionAmount ?? 0), 0);
  const chequeModeTr = data?.trnasactionArray
    ?.filter((item) => item?.paymentMode === "ChequeMode")
    ?.reduce((sum, value) => sum + Number(value?.transactionAmount ?? 0), 0);
  const neftModeTr = data?.trnasactionArray
    ?.filter((item) => item?.paymentMode === "NEFTMode")
    ?.reduce((sum, value) => sum + Number(value?.transactionAmount ?? 0), 0);
  const upiModeTr = data?.trnasactionArray
    ?.filter((item) => item?.paymentMode === "UpiMode")
    ?.reduce((sum, value) => sum + Number(value?.transactionAmount ?? 0), 0);

  const cashModeBalance = Number(
    Number(paymentMode.CashModeEarning ?? 0) -
      Number(paymentMode.CashModeDeduction ?? 0) -
      (Number(state.cashMode.amount ?? 0) + Number(cashModeTr ?? 0))
  ).toFixed(2);

  const neftModeBalance = Number(
    Number(paymentMode.NEFTModeEarning ?? 0) -
      Number(paymentMode.NEFTModeDeduction ?? 0) -
      (Number(state.neftMode.amount ?? 0) + Number(neftModeTr ?? 0))
  ).toFixed(2);

  const chequeModeBalance = Number(
    Number(paymentMode.ChequeModeEarning ?? 0) -
      Number(paymentMode.ChequeModeDeduction ?? 0) -
      (Number(state.chequeMode.amount ?? 0) + Number(chequeModeTr ?? 0))
  ).toFixed(2);

  const cardModeBalance = Number(
    Number(paymentMode.CardModeEarning ?? 0) -
      Number(paymentMode.CardModeDeduction ?? 0) -
      (Number(state.cardMode.amount ?? 0) + Number(cardModeTr ?? 0))
  ).toFixed(2);

  const upiModeBalance = Number(
    Number(paymentMode.UpiModeEarning ?? 0) -
      Number(paymentMode.UpiModeDeduction ?? 0) -
      (Number(state.upiMode.amount ?? 0) + Number(upiModeTr ?? 0))
  ).toFixed(2);

  const errorCashmode = () => {
    if (
      Number(state.cashMode.amount) >
      Number(
        Number(paymentMode.CashModeEarning ?? 0) -
          Number(paymentMode.CashModeDeduction ?? 0) -
          Number(cashModeTr ?? 0)
      ).toFixed(2)
    ) {
      return "more than balance";
    }
  };
  const errorNeftmode = () => {
    if (
      Number(state.neftMode.amount) >
      Number(
        Number(paymentMode.NEFTModeEarning ?? 0) -
          Number(paymentMode.NEFTModeDeduction ?? 0) -
          Number(neftModeTr ?? 0)
      ).toFixed(2)
    ) {
      return "more than balance";
    }
  };
  const errorChequemode = () => {
    if (
      Number(state.chequeMode.amount) >
      Number(
        Number(paymentMode.ChequeModeEarning ?? 0) -
          Number(paymentMode.ChequeModeDeduction ?? 0) -
          +Number(chequeModeTr ?? 0)
      ).toFixed(2)
    ) {
      return "more than balance";
    }
  };
  const errorCardmode = () => {
    if (
      Number(state.cardMode.amount) >
      Number(
        Number(paymentMode.CardModeEarning ?? 0) -
          Number(paymentMode.CardModeDeduction ?? 0) -
          Number(cardModeTr ?? 0)
      ).toFixed(2)
    ) {
      return "more than balance";
    }
  };
  const errorUpimode = () => {
    if (
      Number(state.upiMode.amount) >
      Number(
        Number(paymentMode.UpiModeEarning ?? 0) -
          Number(paymentMode.UpiModeDeduction ?? 0) -
          Number(upiModeTr ?? 0)
      ).toFixed(2)
    ) {
      return "more than balance";
    }
  };

  const totalPaying =
    Number(state.cardMode.amount ?? 0) +
    Number(state.cashMode.amount ?? 0) +
    Number(state.chequeMode.amount ?? 0) +
    Number(state.neftMode.amount ?? 0) +
    Number(state.upiMode.amount ?? 0);
  const toBePaid =
    Number(data?.netPay ?? 0) -
    Number(data?.paidAmount ?? 0) -
    Number(data?.previouslyPaid ?? 0);
  const totalBalance = toBePaid - totalPaying;
  const errorReturn = () => {
    if (totalPaying > toBePaid) {
      return `-> Paying Amount ${totalPaying} Greater than To be Paid ${toBePaid}`;
    }
    return "";
  };
  const handlePay = () => {
    if (errorCashmode()) {
      return;
    }
    if (errorNeftmode()) {
      return;
    }
    if (errorChequemode()) {
      return;
    }
    if (errorCardmode()) {
      return;
    }
    if (errorUpimode()) {
      return;
    }
    const isError = Boolean(errorReturn());
    if (!isError) {
      const totalPayload = {
        balanceAmount: totalBalance || 0,
        transactionDate: state.transactionDate,
        transactionList: [
          {
            transactionAmount: Number(state.cashMode.amount ?? 0),
            paymentMode: "CashMode",
            referenceNumber: state.cashMode.reference_no,
          },
          {
            transactionAmount: Number(state.cardMode.amount ?? 0),
            paymentMode: "CardMode",
            referenceNumber: state.cardMode.reference_no,
          },
          {
            transactionAmount: Number(state.chequeMode.amount ?? 0),
            paymentMode: "ChequeMode",
            referenceNumber: state.chequeMode.reference_no,
          },
          {
            transactionAmount: Number(state.neftMode.amount ?? 0),
            paymentMode: "NEFTMode",
            referenceNumber: state.neftMode.reference_no,
          },
          {
            transactionAmount: Number(state.upiMode.amount ?? 0),
            paymentMode: "UpiMode",
            referenceNumber: state.upiMode.reference_no,
          },
        ],
      };
      onClickSubmit(totalPayload);
    }
  };
  useEffect(() => {
    if (data) {
      const obj = {
        ...initialObj,
      };
      data?.earnings?.forEach((item) => {
        obj[`${item?.paymentMode}Earning`] += Number(item?.amount);
      });
      data?.deductions?.forEach((item) => {
        obj[`${item?.paymentMode}Deduction`] += Number(item?.amount);
      });
      setPaymentMode(obj);
    }
  }, [data]);

  return (
    <Wrapper>
      <Stack direction={"row"} gap={2}>
        <LabelTextComponent
          label={"Total Earnings"}
          text={IndianCurrency(data?.totalEarnings)}
        />
        <LabelTextComponent
          label={"Total Deductions"}
          text={IndianCurrency(data?.totalDeductions)}
        />
        <LabelTextComponent
          label={"Net Payable"}
          text={IndianCurrency(data?.netPay)}
        />
        <LabelTextComponent
          label={"Previously Paid"}
          text={IndianCurrency(data?.previouslyPaid ?? data?.paidAmount)}
        />
        <LabelTextComponent
          label={"To Be Paid"}
          text={IndianCurrency(toBePaid)}
        />
      </Stack>

      <Grid
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        mt={1}
        columns={14}
      >
        <Grid item xs={2} />
        <Grid item xs={3}>
          <Typography className="text">Amount</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className="text">Reference Number</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="text">Config</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="text">Paid</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="text">Balance</Typography>
        </Grid>
      </Grid>
      {/* Cash mode */}
      <Grid
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        mt={0.5}
        columns={14}
      >
        <Grid item xs={2}>
          <Typography className="text">Cash Mode</Typography>
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`cashMode.amount`}
            placeholder="type amount"
            type="number"
            value={state.cashMode.amount}
            onChange={handleChange}
            error={errorCashmode() ? true : false}
            helperText={
              errorCashmode() && (
                <Typography color="error">{errorCashmode()}</Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`cashMode.reference_no`}
            placeholder="Type reference number"
            type="text"
            value={state.cashMode.reference_no}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            {paymentMode.CashModeEarning - paymentMode.CashModeDeduction}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            <span className="price_green">
              {Number(state.cashMode.amount ?? 0) + Number(cashModeTr ?? 0)}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main price_red">
            {cashModeBalance}
          </Typography>
        </Grid>
      </Grid>
      {/* NEFT Mode */}
      <Grid
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        mt={0.5}
        columns={14}
      >
        <Grid item xs={2}>
          <Typography className="text">NEFT Mode</Typography>
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`neftMode.amount`}
            placeholder="type amount"
            type="number"
            value={state.neftMode.amount}
            onChange={handleChange}
            error={errorNeftmode() ? true : false}
            helperText={
              errorNeftmode() && (
                <Typography color="error">{errorNeftmode()}</Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`neftMode.reference_no`}
            placeholder="Type reference number"
            type="text"
            value={state.neftMode.reference_no}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            {paymentMode.NEFTModeEarning - paymentMode.NEFTModeDeduction}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            <span className="price_green">
              {Number(state.neftMode.amount ?? 0) + Number(neftModeTr ?? 0)}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main price_red">
            {neftModeBalance}
          </Typography>
        </Grid>
      </Grid>
      {/* Cheque Mode */}
      <Grid
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        mt={0.5}
        columns={14}
      >
        <Grid item xs={2}>
          <Typography className="text">Cheque Mode</Typography>
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`chequeMode.amount`}
            placeholder="type amount"
            type="number"
            value={state.chequeMode.amount}
            onChange={handleChange}
            error={errorChequemode() ? true : false}
            helperText={
              errorChequemode() && (
                <Typography color="error">{errorChequemode()}</Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`chequeMode.reference_no`}
            placeholder="Type reference number"
            type="text"
            value={state.chequeMode.reference_no}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            {paymentMode.ChequeModeEarning - paymentMode.ChequeModeDeduction}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            <span className="price_green">
              {Number(state.chequeMode.amount ?? 0) + Number(chequeModeTr ?? 0)}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main price_red">
            {chequeModeBalance}
          </Typography>
        </Grid>
      </Grid>
      {/*  Card Mode*/}
      <Grid
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        mt={0.5}
        columns={14}
      >
        <Grid item xs={2}>
          <Typography className="text">Card Mode</Typography>
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`cardMode.amount`}
            placeholder="type amount"
            type="number"
            value={state.cardMode.amount}
            onChange={handleChange}
            error={errorCardmode() ? true : false}
            helperText={
              errorCardmode() && (
                <Typography color="error">{errorCardmode()}</Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`cardMode.reference_no`}
            placeholder="Type reference number"
            type="text"
            value={state.cardMode.reference_no}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            {paymentMode.CardModeEarning - paymentMode.CardModeDeduction}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            <span className="price_green">
              {Number(state.cardMode.amount ?? 0) + Number(cardModeTr ?? 0)}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main price_red">
            {cardModeBalance}
          </Typography>
        </Grid>
      </Grid>
      {/* UPI Mode */}
      <Grid
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        mt={0.5}
        columns={14}
      >
        <Grid item xs={2}>
          <Typography className="text">UPI Mode</Typography>
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`upiMode.amount`}
            placeholder="type amount"
            type="number"
            value={state.upiMode.amount}
            onChange={handleChange}
            error={errorUpimode() ? true : false}
            helperText={
              errorUpimode() && (
                <Typography color="error">{errorUpimode()}</Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            fullWidth
            name={`upiMode.reference_no`}
            placeholder="Type reference number"
            type="text"
            value={state.upiMode.reference_no}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            {paymentMode.UpiModeEarning - paymentMode.UpiModeDeduction}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="price_main">
            <span className="price_green">
              {Number(state.upiMode.amount ?? 0) + Number(upiModeTr ?? 0)}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography className="price_main price_red">
            {upiModeBalance}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        display={"flex"}
        alignItems={"center"}
        mt={1}
        columns={14}
      >
        <Grid item xs={2} />
        <Grid item xs={3}>
          <LabelTextComponent
            label={"Total Paying"}
            text={IndianCurrency(totalPaying)}
          />
        </Grid>
        <Grid xs={7}>
          <TextFieldLabel title={"Transaction Date"} required />
          <DesktopDatePicker
            inputFormat="DD-MM-YYYY"
            onChange={(e) =>
              setState((prev) => ({ ...prev, transactionDate: e }))
            }
            value={state.transactionDate}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                size="small"
                error={!state.transactionDate}
                helperText={
                  !state.transactionDate && "Transaction date required"
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <LabelTextComponent
            label={"Net Balance"}
            text={IndianCurrency(totalBalance)}
          />
        </Grid>
      </Grid>

      <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
        <LoadingButton
          loading={loading}
          variant="contained"
          size="large"
          onClick={handlePay}
          color="secondary"
          sx={{ width: "200px" }}
        >
          Pay
        </LoadingButton>
      </Stack>
    </Wrapper>
  );
};

export default PaymentModeComponent2;
const Wrapper = styled(Box)`
  .text {
    color: var(--primary);
    font-weight: 500;
    font-size: 14px;
  }
  .price_main {
    font-size: 14px;
    font-weight: 600;
    .price_green {
      color: #5bce00;
    }
  }
  .price_red {
    color: #f10f0f;
  }
`;
