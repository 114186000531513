import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import styled from "styled-components";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { ToWords } from "to-words";
import SchoolPrintHeader from "app/components/common/SchoolPrintHeader";

const SalarySlip = React.forwardRef((props, ref) => {
  const toWords = new ToWords();

  const totalEarnings = props?.earnings?.reduce(
    (sum, value) => sum + Number(value?.amount ?? 0),
    0
  );
  const totalDeductions = props?.deductions?.reduce(
    (sum, value) => sum + Number(value?.amount ?? 0),
    0
  );
  props.setAmount(Number(Number(totalEarnings) - Number(totalDeductions)));

  return (
    <>
      <Stack
        ref={ref}
        border="1px solid #b6c8d6"
        minHeight="90vh"
        sx={{ backgroundColor: "#fff" }}
      >
        <SchoolPrintHeader />
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            variant="contained"
            color="secondary"
            sx={{
              height: "50px",
              mt: 1,

              backgroundColor: (theme) => theme.palette.secondary.main,
            }}
          >
            <Typography sx={{ color: "#fff", fontSize: "1.5rem" }}>
              Salary Slip for {props?.data?.month} {props?.data?.year}
            </Typography>
          </Stack>
        </Stack>
        <Stack padding="20px">
          <Title>Employee Info</Title>
          <Stack>
            {/* grid of 2x5 */}
            <CustomBox>
              <div className="grid-container">
                <div className="grid-item">
                  <div className="sub">PS Number</div>
                  <div className="content">{props?.data?.psNumber || "--"}</div>
                </div>
                <div className="grid-item">
                  <div className="sub">Date of joining</div>
                  <div className="content">
                    {moment(
                      new Date(props?.data?.employmentInfo?.joiningDate)
                    ).format("DD-MM-YYYY") || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">Emp Id</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.employeeId || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">Department</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.departmentName || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">Designation</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.designation || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">Full Name</div>
                  <div className="content">
                    {`${props?.data?.employmentInfo?.staffDetail?.firstName} ${
                      props?.data?.employmentInfo?.staffDetail?.middleName ?? ""
                    } ${
                      props?.data?.employmentInfo?.staffDetail?.lastName ?? ""
                    }` || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">PAN Number</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.staffDetail?.PAN || "--"}
                  </div>
                </div>
                {/* <div className="grid-item">
                  <div className="sub">PF number</div>
                  <div className="content">{props?.data?.PF || "--"}</div>
                </div>
                <div className="grid-item">
                  <div className="sub">EPS number</div>
                  <div className="content">{props?.data?.EPS || "--"}</div>
                </div> */}
                <div className="grid-item">
                  <div className="sub">Bank Name</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.staffDetail?.bankDetails
                      ?.bankName || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">Account number</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.staffDetail?.bankDetails
                      ?.accountNumber || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">Branch</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.staffDetail?.bankDetails
                      ?.branch || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">Holder Name</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.staffDetail?.bankDetails
                      ?.holderName || "--"}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="sub">IFS Code</div>
                  <div className="content">
                    {props?.data?.employmentInfo?.staffDetail?.bankDetails
                      ?.ifscCode || "--"}
                  </div>
                </div>
              </div>
            </CustomBox>
          </Stack>
        </Stack>
        <Stack padding="20px">
          <Title>Attendance Detail</Title>
          <Data>
            {/* data */}
            <div>
              <div className="sub">Total Working Days</div>
              <div className="content">{props?.data?.totalDays}</div>
            </div>
            <div>
              <div className="sub">Total Present Days</div>
              <div className="content">{props?.data?.presentDays}</div>
            </div>
            <div>
              <div className="sub">Total Half Days</div>
              <div className="content">{props?.data?.halfDays}</div>
            </div>
            <div>
              <div className="sub">Total Absent</div>
              <div className="content">{props?.data?.absentDays}</div>
            </div>
            <div>
              <div className="sub">Total Paid Leave</div>
              <div className="content">{props?.data?.leaveDays}</div>
            </div>
            <div>
              <div className="sub">Total Holidays</div>
              <div className="content">{props?.data?.holidays}</div>
            </div>

            <div></div>
          </Data>
          <Title>Salary Detail</Title>
          <Stack direction="row" spacing={4}>
            <Stack flex={0.7}>
              {/* first table */}
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: 500,
                  borderRadius: "10px",
                  border: "1px solid #B6C8D6",
                }}
              >
                <Table
                  sx={{ borderRadius: "10px", textAlign: "left" }}
                  aria-label="simple table"
                >
                  <TableHead
                    style={{ background: "#F6F8FC", borderRadius: "10px" }}
                  >
                    <TableRow>
                      <TableCell>Sr no.</TableCell>
                      <TableCell>Head Name</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Payment Mode</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props?.earnings?.length !== 0 ? (
                      props?.earnings?.map((row, index) => (
                        <TableRow
                          key={row?.headName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell>{row?.headName}</TableCell>

                          <TableCell>{row?.amount}</TableCell>
                          <TableCell>{row?.paymentMode}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>no earning</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow
                      style={{
                        background: "rgba(8, 76, 132, 0.1)",
                        border: "1px solid #B6C8D6",
                        bordeRadius: "0px 0px 15px 15px",
                        width: "100%",
                      }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "1.2rem",
                          color: "#0C2F49",
                          fontWeight: "600",
                        }}
                        colSpan={5}
                      >
                        TotalEarnings:{" "}
                        {totalEarnings?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "INR",
                        })}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Stack>
            <Stack flex={0.7}>
              {/* second table */}
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: 500,
                  borderRadius: "10px",
                  border: "1px solid #B6C8D6",
                }}
              >
                <Table
                  sx={{ border: "1px solid #B6C8D6", borderRadius: "15px" }}
                  aria-label="simple table"
                >
                  <TableHead style={{ background: "#F6F8FC" }}>
                    <TableRow>
                      <TableCell>Sr no.</TableCell>
                      <TableCell>Head Name</TableCell>

                      <TableCell>Amount</TableCell>
                      <TableCell>Payment Mode</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props?.deductions?.length !== 0 ? (
                      props?.deductions?.map((row, index) => (
                        <TableRow
                          key={row?.headName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell>{row?.headName}</TableCell>

                          <TableCell>{row?.amount}</TableCell>
                          <TableCell>{row?.paymentMode}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>no deductions</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow
                      style={{
                        background: "rgba(8, 76, 132, 0.1)",
                        border: "1px solid #B6C8D6",
                        bordeRadius: "0px 0px 15px 15px",
                        width: "100%",
                      }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "1.2rem",
                          color: "#0C2F49",
                          fontWeight: "600",
                        }}
                        colSpan={5}
                      >
                        TotalDeductions:{" "}
                        {totalDeductions?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "INR",
                        })}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" padding="20px" spacing={2}>
          {totalEarnings || totalDeductions ? (
            <>
              <Net>Net Pay:</Net>
              <Net style={{ opacity: "0.75" }}>
                {(totalEarnings - totalDeductions)?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}{" "}
                /- (
                {toWords.convert(Number(totalEarnings - totalDeductions), {
                  currency: true,
                })}
                )
              </Net>
            </>
          ) : (
            <>
              <Net>Net Pay:</Net>
              <Net style={{ opacity: "0.75" }}>0</Net>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
});

export default SalarySlip;

const CustomBox = styled.div`
  font-family: "Inter";
  font-style: normal;
  color: #0c2f49;
  background: rgba(208, 208, 226, 0.08);
  border: 1px solid #d0d0e2;
  border-radius: 15px;
  padding: 20px;
  .grid-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
  }
  .grid-item {
    padding: 10px;
    /* background: #9e9d9d; */
  }
  .sub {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.75;
  }
  .content {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;

  color: #0c2f49;
  margin-bottom: 10px;
`;

const Data = styled.div`
  font-family: "Inter";
  font-style: normal;
  color: #0c2f49;
  display: flex;
  gap: 20px;
  .sub {
    opacity: 0.75;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .content {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }
`;

const Net = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0c2f49;
`;
