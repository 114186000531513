import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  FormControlLabel,
  Checkbox,
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { format } from "date-fns";
import { useMutation, useQuery } from "react-query";
import {
  deleteInternalTransfer,
  getInternalTransfer,
  updateStatusInternalTransfer,
} from "app/services/ledgerAccount";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import DialogHeader from "app/components/common/DialogHeader";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { IndianCurrency } from "app/utils/helper";
import { useSelector } from "react-redux";
import { loggedInUserProfileSelector } from "app/modules/auth/selectors";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as XLSX from "xlsx";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import TransferReportFilter from "app/components/common/Dialogs/ledger/TransferReportFilter";
import TableActionButtons from "app/components/common/TableActionButtons";
import AddTransferReportDialog from "app/components/common/Dialogs/ledger/AddTransferReportDialog";
import dayjs from "dayjs";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";

const TransferReport = () => {
  const [state, setState] = useState({
    filteredData: false,
    fetch: false,
  });
  const logged = useSelector(loggedInUserProfileSelector);
  const [active, setActive] = useState(null);

  const [modals, setModals] = useState({
    addInternal: "",
    edit: "",
    delete: false,
  });

  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    export: false,
  });

  const [filtered, setFiltered] = useState([]);
  const formatDate = (data) => {
    const date = new Date(data);
    return format(date, "yyyy-MM-dd");
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const columns = [
    {
      header: "Sr.No",
      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
      size: 20,
    },
    {
      header: "Transfer From",
      accessorKey: "from",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.from}</Typography>;
      },
      size: 40,
    },
    {
      header: "Transfer To",
      accessorKey: "to",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.to}</Typography>;
      },
      size: 40,
    },
    {
      header: "Transfer On",
      accessorKey: "transferOn",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {original?.transferOn
            ? dayjs(original?.transferOn).format("DD MMM, YYYY")
            : "--"}
        </Typography>
      ),
      size: 20,
    },
    {
      header: "Amount",
      accessorKey: "amt",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.amt}</Typography>;
      },
      size: 30,
    },
    {
      header: "Created On",
      accessorKey: "createdOn",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {original?.createdOn
            ? dayjs(original?.createdOn).format("DD MMM, YYYY")
            : "--"}
        </Typography>
      ),
      size: 20,
    },

    {
      header: "Remarks",
      accessorKey: "remarks",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.remark}</Typography>;
      },
      size: 50,
    },
    {
      header: "Status",
      accessorKey: "status",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            sx={
              original?.status?.toLowerCase() === "approved"
                ? {
                    color: "green",
                  }
                : original?.status?.toLowerCase() === "requested"
                ? { color: "orange" }
                : original?.status?.toLowerCase() === "rejected"
                ? { color: "red" }
                : {}
            }
          >
            {original?.status}
          </Typography>
        );
      },
      size: 20,
    },
    {
      header: "Actions",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            {original.status.toLowerCase() === "requested" && (
              <TableActionButtons
                showEdit
                showDelete
                handleDelete={() => {
                  setActive(original);
                  setModals((prev) => ({ ...prev, delete: true }));
                }}
                handleEdit={() => {
                  setActive(original);
                  setDialogs((prev) => ({ ...prev, edit: true }));
                }}
              />
            )}
          </>
        );
      },
      size: 20,
    },
  ];

  const {
    refetch: getInternalTransferRefetch,
    isLoading: getTransferFilteredLoading,
    isFetching: getTransferFilteredFetching,
  } = useQuery({
    queryKey: ["getInternalTransfer", state.fetch],
    queryFn: () => {
      if (state.filteredData) {
        return getInternalTransfer(state.filteredData);
      } else {
        return getInternalTransfer();
      }
    },
    onSuccess: (res) => {
      setFiltered(res?.data?.list);
    },
    onError: (err) => toast.error(err?.response?.data?.message),
  });

  const {
    mutate: updateStatusInternalTransferMutate,
    isLoading: updateStatusInternalTransferLoading,
  } = useMutation(updateStatusInternalTransfer, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      setDialogs((prev) => ({ ...prev, edit: false }));
      getInternalTransferRefetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: deleteInternalTransferMutate,
    isLoading: deleteInternalTransferLoading,
  } = useMutation(deleteInternalTransfer, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      setModals((prev) => ({ ...prev, delete: false }));
      getInternalTransferRefetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  let exportArray = filtered?.map((item) => {
    return {
      Transfer_From: item?.transferFrom?.personName || "",
      Transfer_To: item?.transferTo?.personName || "",
      Transfer_On: item?.transferOn ? formatDate(item?.transferOn) : "",
      Status: item?.status || "",
      Session: item?.session || "",
      Remarks: item?.remarks || "",
      Created_On: item?.createdOn ? formatDate(item?.createdOn) : "",
      Amount: IndianCurrency(item?.amount) || "",
    };
  });

  let header = [
    "Transfer_From",
    "Transfer_To",
    "Transfer_On",
    "Status",
    "Session",
    "Remarks",
    "Created_On",
    "Amount",
  ];

  const [selectedOptions, setSelectedOptions] = useState(header);

  const handleExport = () => {
    const filteredExportArray = exportArray.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transfer Report");
    XLSX.writeFile(workbook, "TransferReport.xlsx");
  };
  const handleFilter = (payload) => {
    setState((prev) => ({
      ...prev,
      filteredData: payload,
      fetch: !prev.fetch,
    }));
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  return (
    <>
      <CustomLoader
        show={getTransferFilteredLoading || getTransferFilteredFetching}
      />
      {/* add transfer report dialog */}
      <AddTransferReportDialog
        open={dialogs?.add}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, add: false }));
        }}
        refetch={getInternalTransferRefetch}
      />
      {/* delete confirmation */}

      <Modal
        open={modals.delete}
        onClose={() => setModals((prev) => ({ ...prev, delete: true }))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* delete modal */}
        <Stack sx={{ ...modalStyle, width: 350 }}>
          <Stack padding={2} direction="column" spacing={1}>
            <Stack alignItems="center" justifyContent="center">
              <AccountCircleIcon
                sx={{
                  fontSize: "8rem",
                  color: "#36588b",
                }}
              />
              {/* <AccountCircleTwoToneIcon sx={{
                  fontSize: "8rem"
                }}/> */}
            </Stack>

            <Stack fontSize={30} alignItems="center" justifyContent="center">
              <Typography sx={{ fontSize: "16px" }}>Are you sure to</Typography>
              <Typography sx={{ fontSize: "16px" }}>
                <span style={{ color: "#F10F0F", fontWeight: "700" }}>
                  Delete
                </span>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() =>
                  setModals((prev) => ({ ...prev, delete: false }))
                }
              >
                Cancel
              </Button>
              <LoadingButton
                loading={deleteInternalTransferLoading}
                onClick={() => deleteInternalTransferMutate(active.id)}
                variant="contained"
                color="secondary"
                size="large"
              >
                Confirm
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
      {/* edit dialog */}
      <CommonDialog
        open={dialogs?.edit}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, edit: false }));
        }}
        title="Transfer Amount"
        minWidth="600px"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Label>Transfer From</Label>
              <Label sx={{ fontWeight: "700" }}>{active?.from || "--"}</Label>
            </Grid>
            <Grid item xs={12} md={4}>
              <Label>Transfer To</Label>
              <Label sx={{ fontWeight: "700" }}>{active?.to || "--"}</Label>
            </Grid>
            <Grid item xs={12} md={4}>
              <Label>Amount</Label>
              <Label sx={{ fontWeight: "700" }}>
                {IndianCurrency(active?.amt)}
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label>Remark</Label>
              <Label sx={{ fontWeight: "600" }}>{active?.remark}</Label>
            </Grid>
          </Grid>
          <Stack direction="row" gap={2}>
            <Stack flex={1}></Stack>
            <Stack flex={1}></Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          {/* {logged?._id === active?.toId && () */}
          <>
            <LoadingButton
              loading={
                active?.btn === "R" ? updateStatusInternalTransferLoading : null
              }
              size="large"
              variant="outlined"
              color="secondary"
              onClick={() => {
                updateStatusInternalTransferMutate({
                  internalTransferDocId: active?.id,
                  status: "REJECTED",
                });
                setActive({ ...active, btn: "R" });
              }}
            >
              Reject
            </LoadingButton>
            <LoadingButton
              loading={
                active?.btn === "A" ? updateStatusInternalTransferLoading : null
              }
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => {
                updateStatusInternalTransferMutate({
                  internalTransferDocId: active?.id,
                  status: "APPROVED",
                });
                setActive({ ...active, btn: "A" });
              }}
            >
              Approve
            </LoadingButton>{" "}
          </>
        </DialogActions>
      </CommonDialog>

      {/* export dialog */}
      <Dialog
        open={dialogs.export}
        onClose={() =>
          setDialogs((prev) => ({
            ...prev,
            export: false,
          }))
        }
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to export"
          handleClose={() =>
            setDialogs((prev) => ({
              ...prev,
              export: false,
            }))
          }
        />
        <DialogContent>
          <Box>
            {header.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setDialogs((prev) => ({
                ...prev,
                export: false,
              }));
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>
      <Stack py={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TransferReportFilter mutate={handleFilter} />
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Button
              onClick={() => {
                setDialogs((prev) => ({ ...prev, add: true }));
              }}
              variant="outlined"
              color="secondary"
              size="large"
            >
              + Add New
            </Button>
            <Box sx={{ mt: "-10px" }}>
              <ExportButtonGroup
                showPdf={false}
                handleClickExcel={() =>
                  setDialogs((prev) => ({ ...prev, export: true }))
                }
              />
            </Box>
          </Stack>
        </Stack>
        <Stack mt={2}>
          <CommonTable1
            columns={columns}
            data={
              filtered.length === 0
                ? ""
                : filtered.map((item) => ({
                    id: item._id,
                    amt: item.amount || "--",
                    from: item.transferFrom?.personName || "--",
                    fromId: item.transferFrom?.personDocId || "--",
                    to: item.transferTo?.personName || "--",
                    toId: item.transferTo?.personDocId || "--",
                    remark: item.remarks || "--",
                    status: item.status || "--",
                    createdOn: item?.createdOn,
                    transferOn: item?.transferOn,
                  }))
            }
          />
        </Stack>
      </Stack>
    </>
  );
};

export default TransferReport;

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));
